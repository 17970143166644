
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('cotton_config.hat') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.division')"
                            label-for="division_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.division_id"
                                :options="divisionList"
                                id="division_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.district')"
                            label-for="district_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.district_id"
                                :options="districtList"
                                id="district_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.upazila')"
                            label-for="upazila_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.upazilla_id"
                                :options="upazilaList"
                                id="upazila_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col  xs="12" sm="12" md="6">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('cotton_config.hat_name')"
                        label-for="hat_name"
                        >
                        <b-form-input
                            id="hat_name"
                            v-model="search.hatt_name"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="text-right">
                          <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('cotton_config.hat_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(zone_name)="data">
                                            {{ data.item.zone_name }}
                                        </template>
                                        <template v-slot:cell(zone_name_bn)="data">
                                            {{ data.item.zone_name_bn }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Sales Center Modify" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Click to Deactive" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Click to Active" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { hatList, hatStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        division_id: '0',
        district_id: '0',
        upazilla_id: '0',
        hatt_name: ''
      },
      rows: [],
      districtList: [],
      upazilaList: []
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('cotton_config.hat_entry') : this.$t('cotton_config.hat') + ' ' + this.$t('globalTrans.modify')
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('org_pro_division.division'), class: 'text-center' },
          { label: this.$t('org_pro_district.district'), class: 'text-center' },
          { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-center' },
          { label: this.$t('cotton_config.hat_name'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazila_name_bn' },
          { key: 'hatt_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'district_name' },
          { key: 'upazila_name' },
          { key: 'hatt_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal) {
        this.districtList = this.getDistrictList(this.search.division_id)
        this.upazilaList = this.getUpazilaList(this.search.district_id)
      }
    },
    'search.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(agriMarketingServiceBaseUrl, hatStatus, item, 'agriMarketing', 'hatList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriMarketingServiceBaseUrl, hatList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList

      const listData = data.map(item => {
        const divisionObject = divisionList.find(division => division.value === item.division_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazila => upazila.value === item.upazilla_id)

        const divisionData = { division_name: divisionObject.text_en, division_name_bn: divisionObject.text_bn }
        const districtData = { district_name: districtObject.text_en, district_name_bn: districtObject.text_bn }
        const upazilaData = { upazila_name: upazilaObject.text_en, upazila_name_bn: upazilaObject.text_bn }
        return Object.assign({}, item, divisionData, districtData, upazilaData)
      })
      return listData
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    }
  }
}
</script>
